































import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api'
import { useConfig } from '~/composables';
import { Logger } from '~/helpers/logger';
import { getAsJSON } from '~/utils/solepro/CmsComponentAggregator';
import { hubView, hubLink } from '~/helpers/trackings/24orepro/pages';

export default defineComponent({
    props: {
        content: {
            type: String,
            required: true
        }

    },
    setup(props) {
        const { content } = props;
        const jsonContent = getAsJSON(content);

        let blocks = ref<{ image?: any, title: string, products?: any[], links?: any[] }[]>([]); // @TODO better define products attribute type
        let tmpBlocks = []
        try {
            jsonContent.children.forEach(el => {
                let title = 'Altri Prodotti' //fallback
                const img = el.children.find(el => el.contentType == 'image');
                let image;
                try {
                    title = img.DOMContent.querySelector("[data-element=caption]").innerHTML;
                    image = img.DOMContent.querySelector("[data-element=desktop_image]")
                } catch { }
                

                    el.children.filter(c => c.contentType == 'buttons').forEach(btnGroup => {
                        const links = btnGroup.children.map(link => {
                            return {
                                href: link.DOMContent.querySelector('a')?.href,
                                text: link.DOMContent.querySelector(['[data-element=link_text]'])?.innerHTML
                            }
                        }).filter(el => !!el.href)
                        tmpBlocks.push({
                            image,
                            title,
                            links
                        })
                    })
                
            })
            tmpBlocks = tmpBlocks.filter(el => el.links && el.links.length > 0)

        } catch (e) {
            Logger.error('Error in HubContent')
            Logger.error(e)
        }

        const { config } = useConfig()

        blocks.value = tmpBlocks;

        const trackHubLink = (val, callback) => hubLink(val, callback)
        
        useFetch(async () => {
            await hubView();
        })

        return {
            trackHubLink,
            blocks,
            storeCode: config.value.store_code
        }
    },
    methods:{
        scrollTo(id){
            document.getElementById(id).scrollIntoView()
        },
        goToPage(link) {
            window.location.href = link;
        }
    }
})
