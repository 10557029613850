



























import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api'
import HubContent from '~/components/solepro/HubContent.vue';
import { useContent } from "~/composables";



export default defineComponent({
  components: { HubContent },
    layout: '24orepro',
    setup() {
        const { loadBlocks } = useContent();
        const hubContent = ref()
        useFetch(async () => {
            const cmsContent = await loadBlocks({ identifiers: ["solepro_hub_content"] });
            hubContent.value = cmsContent[0]?.content
        })
        return {
            hubContent
        }
    }
    
})
